
export default {
  data() {
    return {
      rute: [
        { path: '/', value: 'Početna' },
        { path: '/usluge/', value: 'Usluge' },
        { path: '/o-nama/', value: 'O nama' },
        { path: '/kontakt/', value: 'Kontakt' },
      ],
      mobile: false,
      mobileNav: false,
    }
  },

  updated() {
    this.$nextTick(this.positionLine)
  },

  mounted() {
    const mql = window.matchMedia('(max-width: 63em)')
    if (mql.matches) {
      this.mobile = true
    }

    const line = this.$refs.line
    const aktivno = document.querySelector('#d-nav .aktivno')
    if (aktivno) {
      this.positionLine(line, aktivno.offsetLeft, aktivno.offsetWidth)
    }
  },
  methods: {
    handleResize({ width, height }) {
      if (width > 1008) {
        this.mobile = false
        this.mobileNav = false
      } else {
        this.mobile = true
      }
    },
    lineLeftWidth: function (el, left, width) {
      el.style.left = left + 'px'
      el.style.width = width + 'px'
    },
    positionLine: function () {
      if (!this.mobile) {
        const line = this.$refs.line

        const aktivno = document.querySelector('#d-nav .aktivno')
        if (aktivno) {
          this.lineLeftWidth(line, aktivno.offsetLeft, aktivno.offsetWidth)
        }
      }
    },
    lineHover: function (e) {
      const line = this.$refs.line
      this.lineLeftWidth(line, e.target.offsetLeft, e.target.offsetWidth)
    },
    beforeEnter: function (el) {
      this.$gsap.set(el, { yPercent: -100 })
      this.$gsap.set('#m-nav li, .m-logotip, .izlaz, .menu-tel', {
        y: 30,
        opacity: 0,
      })
      this.$gsap.set('.izlaz .g-c', {
        y: 6,
        scaleX: 0.75,
        transformOrigin: '50%',
        rotation: 45,
      })
      this.$gsap.set('.izlaz .d-c', {
        y: -6,
        scaleX: 0.75,
        transformOrigin: '50%',
        rotation: -45,
      })
      this.$gsap.set('html', { overflow: 'hidden' })
    },
    enter: function (el, done) {
      const tl = this.$gsap.timeline({ defaults: { ease: 'curve' } })
      tl.to(el, { yPercent: 0, duration: 0.6, opacity: 1 }).to(
        '.m-logotip, .izlaz, #m-nav li, .menu-tel',
        { y: 0, opacity: 1, stagger: 0.1 },
        'x'
      )
    },
    leave: function (el, done) {
      const tl = this.$gsap.timeline({ defaults: { ease: 'c-reversed' } })
      tl.to('.m-logotip, .izlaz, #m-nav li, .menu-tel', {
        y: -30,
        opacity: 0,
        stagger: { each: 0.1, from: 'end' },
      }).to(el, { yPercent: -100, duration: 0.6, onComplete: done })
    },
    afterLeave: function () {
      this.$gsap.set('html', { overflow: 'auto' })
    },
  },
}
