
import { bootstrap } from 'vue-gtag'

export default {
  data() {
    return {
      isOpen: false,
    }
  },
  mounted() {
    if (!this.getGDPR() === true) {
      this.isOpen = true
    }
  },
  methods: {
    deny() {
      if (process.browser) {
        this.isOpen = false
        localStorage.setItem('Kolacici', false)
      }
    },
    accept() {
      if (process.browser) {
        bootstrap().then((gtag) => {
          this.isOpen = false
          localStorage.setItem('Kolacici', true)
        })
      }
    },
    getGDPR() {
      if (process.browser) {
        return localStorage.getItem('Kolacici', true)
      }
    },
    beforeEnter: function (el) {
      this.$gsap.set(el, { autoAlpha: 0, yPercent: 100})
    },
    enter: function (el, done) {
      const tl = this.$gsap.timeline({ defaults: { ease: 'curve' } })
      tl.to(el, { autoAlpha: 1, duration: 1, delay: 4, yPercent: 0, onComplete: done })
    },
    leave: function (el, done) {
      const tl = this.$gsap.timeline({ defaults: { ease: 'curve' } })

      tl.to(el, {
        autoAlpha: 0,
        duration: 1,
        yPercent: 100,
        onComplete: done
      })
    },
  },
}
