import Vue from 'vue';
import VueGtag from 'vue-gtag';

const getGDPR = localStorage.getItem('Kolacici');

export default ({ app }) => {
  Vue.use(
    VueGtag,
    {
      config: {
        id: 'G-RDHBXSSHS0',
      },
      bootstrap: getGDPR === 'true',
      appName: 'Lighthaus',
      pageTrackerScreenviewEnabled: true,
    },
    app.router
  );
};
