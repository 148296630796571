var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"lh-120",style:([{ padding: _vm.$fluid(60, 90) + ' 0' }, { fontSize: _vm.$fluid(14, 18) }])},[_c('SvgLogo',{staticClass:"logo"}),_vm._v(" "),_c('div',{staticClass:"fw-500 copy-right"},[_c('p',[_vm._v("LightHaus")]),_vm._v(" "),_c('p',[_vm._v("©2023")]),_vm._v(" "),_c('LhUzaCrtica',{staticClass:"spacer",style:([
        { marginTop: _vm.$fluid(24, 36) },
        { marginBottom: _vm.$fluid(16, 18) },
      ]),attrs:{"color":"var(--c-pr-dk) / .5"}}),_vm._v(" "),_c('div',{staticClass:"fw-500 social"},[_c('a',{style:({ marginRight: _vm.$fluid(12, 20) }),attrs:{"href":"https://www.facebook.com/LightHausZG","target":"_blank"}},[_vm._v("Facebook ↗")]),_vm._v(" "),_c('a',{attrs:{"href":"mailto:info@lighthaus.hr"}},[_vm._v("Email ↗")])])],1),_vm._v(" "),_c('h3',{staticClass:"fw-500 radno-vrijeme"},[_vm._v("Radno vrijeme")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('h3',{staticClass:"fw-500 hitne-intervencije"},[_vm._v("Hitne intervencije")]),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('h3',{staticClass:"fw-500 adresa"},[_vm._v("Adresa")]),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sati"},[_c('div',{staticClass:"tjedan"},[_c('p',[_vm._v("Pon - Pet:")]),_vm._v(" "),_c('p',[_vm._v("08:00 - 17:00h")])]),_vm._v(" "),_c('div',[_c('p',[_vm._v("Sub:")]),_vm._v(" "),_c('p',[_vm._v("09:00 - 12:00h")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tel"},[_c('p',[_vm._v("TEL")]),_vm._v(" "),_c('p',[_vm._v("+385 99 664 3756")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ulica"},[_c('p',[_vm._v("Srešov Klanac 28b,")]),_vm._v(" "),_c('p',[_vm._v("10090 Zagreb")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fw-500 legal-info"},[_c('a',{attrs:{"href":"/legal-info/","target":"_blank","aria-label":"Legal info"}},[_vm._v("Legal info")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fw-500 pravila-privatnosti"},[_c('a',{attrs:{"href":"/pravila-privatnosti/","target":"_blank","aria-label":"Pravila privatnosti"}},[_vm._v("Pravila privatnosti")])])
}]

export { render, staticRenderFns }