
export default {
  head() {
    return {
      bodyAttrs: {
        class: this.$route.name,
      },
    }
  },
  mounted() {
    this.$CustomEase.create('curve', '.075,.82,.165,1')
    this.$CustomEase.create('c-reversed', '0.835, 0, 0.925, 0.18')
  },
}
