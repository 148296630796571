export default ({ app }, inject) => {
  inject('fluid', (minF, maxF) => {
    let m
    let b
    const minV = 768
    const maxV = 1920
    m = (maxF - minF) / (maxV - minV)
    b = minF - m * minV
    m = m * 100
    b = b / 10
    minF = minF / 10
    return `max(${minF.toFixed(3)}rem, ${m.toFixed(3)}vw + ${b.toFixed(3)}rem)`
  })
}
