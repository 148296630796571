
import lottie from 'vue-lottie/src/lottie.vue'
import * as animationData from "~/assets/intro.json";

export default {
  components: {
    lottie
  },
  data() {
    return {
      lottieOptions: { animationData: animationData.default, loop: false }
    }
  },
  mounted() {
  },
  methods: {
    handleAnimation: function () {
      this.$gsap.to('.pocetna-animacija', {yPercent: -100, delay: 2.2, ease: 'curve', duration: 1.2})
    }
  },


}
